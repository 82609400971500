@import url(https://fonts.googleapis.com/css2?family=Assistant&family=Roboto+Slab&family=Ruda&family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Almarai&family=Long+Cang&display=swap);
html {
    background-color: #faf8fb; ; 
}

body {
    min-height: 400px;
    margin-bottom: 100px;
    clear: both;
}

.nav_EN, .nav_HE, section {
    -webkit-animation: fadeInAnimation ease 1s;
            animation: fadeInAnimation ease 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
  
@-webkit-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
@media only screen and (min-width: 541px) {
  .nav_EN {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #372a6a;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .social_logos {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 0;
  }

  .social_logos img {
    width: 40px;
    margin-right: 10px;
  }

  hr {
    border: none;
    background-color: #ffecdb;
    color: #ffecdb;
    height: 1px;
  }

  .nav_EN h4 a {
    color: #ffecdb;
    text-decoration: none;
    font-family: "Ruda", sans-serif;
    font-size: 24pt;
  }

  .nav_EN ul {
    list-style: none;
    padding: 0;
  }

  .nav_EN ul li {
    margin-top: 10%;
  }

  .nav_EN ul li a {
    color: #ffecdb;
    font-family: "Ruda", sans-serif;
    font-size: 18pt;
    text-decoration: none;
    padding: 2%;
  }

  .nav_EN ul li button {
    color: #00ff7f;
    font-size: 18pt;
    text-decoration: none;
    border: none;
    background-color: inherit;
    font-family: "Varela Round", sans-serif;
    cursor: pointer;
  }

  .nav_EN ul li a:hover,
  .nav_EN ul li button:hover {
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
  }

  .selected_nav_EN {
    background-color: #d6304e;
    color: #fffacd;
    padding: 2%;
  }
}

@media only screen and (max-width: 540px) {
  .nav_EN {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #372a6a;
    overflow-x: hidden;
    text-align: center;
  }

  .social_logos {
    display: none;
  }

  .social_logos img {
    width: 40px;
    margin-right: 10px;
  }

  hr {
    border: none;
    background-color: #ccc;
    color: #ccc;
    height: 1px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .nav_EN h4 a {
    color: #ffecdb;
    text-decoration: none;
    font-family: "Ruda", sans-serif;
    font-size: 24pt;
  }

  .nav_EN ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 0;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .nav_EN ul li {
    padding: 2%;
    white-space: nowrap;
  }

  .nav_EN ul li a {
    color: #ffecdb;
    font-family: "Ruda", sans-serif;
    font-size: 14pt;
    text-decoration: none;
    padding: 2%;
  }

  .nav_EN ul li button {
    color: #00ff7f;
    font-size: 14pt;
    text-decoration: none;
    border: none;
    background-color: inherit;
    font-family: "Varela Round", sans-serif;
    cursor: pointer;
  }

  .nav_EN ul li a:hover,
  .nav_EN ul li button:hover {
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
  }

  .selected_nav_EN {
    background-color: #d6304e;
    color: #fffacd;
    padding: 2%;
  }
}

@media only screen and (min-width: 541px) {
  .nav_HE {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #372a6a;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  hr {
    border: none;
    background-color: #ffecdb;
    color: #ffecdb;
    height: 1px;
  }

  .nav_HE h4 a {
    color: #ffecdb;
    text-decoration: none;
    font-family: "Varela Round", sans-serif;
    font-size: 24pt;
  }

  .nav_HE ul {
    list-style: none;
    padding: 0;
  }

  .nav_HE ul li {
    margin-top: 10%;
  }

  .nav_HE ul li a {
    color: #ffecdb;
    font-family: "Varela Round", sans-serif;
    font-size: 18pt;
    text-decoration: none;
    padding: 2%;
  }

  .nav_HE ul li button {
    color: #00ff7f;
    font-size: 18pt;
    text-decoration: none;
    border: none;
    background-color: inherit;
    font-family: "Ruda", sans-serif;
    cursor: pointer;
  }

  .nav_HE ul li a:hover,
  .nav_HE ul li button:hover {
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
  }

  .selected_nav_HE {
    background-color: #d6304e;
    color: #fffacd;
    padding: 2%;
  }
}

@media only screen and (max-width: 540px) {
  .nav_HE {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #372a6a;
    overflow-x: hidden;
    text-align: center;
  }

  hr {
    border: none;
    background-color: #ffecdb;
    color: #ffecdb;
    height: 1px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .nav_HE h4 a {
    color: #ffecdb;
    text-decoration: none;
    font-family: "Varela Round", sans-serif;
    font-size: 24pt;
  }

  .nav_HE ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 0;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .nav_HE ul li {
    padding: 2%;
    white-space: nowrap;
  }

  .nav_HE ul li a {
    color: #ffecdb;
    font-family: "Varela Round", sans-serif;
    font-size: 14pt;
    text-decoration: none;
    padding: 2%;
  }

  .nav_HE ul li button {
    color: #00ff7f;
    font-size: 14pt;
    text-decoration: none;
    border: none;
    background-color: inherit;
    font-family: "Ruda", sans-serif;
    cursor: pointer;
    padding: 2%;
  }

  .nav_HE ul li a:hover,
  .nav_HE ul li button:hover {
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
  }

  .selected_nav_HE {
    background-color: #d6304e;
    color: #fffacd;
    padding: 2%;
  }
}

@media only screen and (min-width: 541px) {
  .section_EN {
    padding-top: 50px;
    padding-left: 320px;
    background-color: inherit;
  }

  .rotating_message {
    font-size: 42pt;
    line-height: 1;
    font-family: "Varela Round", "Almarai", sans-serif;
    text-shadow: 0 1px 0 blue, 0 2px 0 red, 0 3px 0 blue, 0 4px 0 red,
      0 20px 30px rgba(0, 0, 0, 0);
  }

  .body_text_EN {
    font-family: "Roboto Slab", serif;
    font-size: 14pt;
    margin-right: 40px;
    color: #05182a;
  }

  .smaller {
    font-size: 10pt;
  }

  .section_EN .home_links {
    font-size: 14pt;
    font-family: "Ruda", sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .section_EN .home_links a {
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 1%;
    margin: 10px;
    border: #372a6a solid;
    border-radius: 50px;
  }

  .section_EN .home_links a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (max-width: 540px) {
  .section_EN {
    margin: 170px 10px 0 10px;
  }

  .rotating_message {
    line-height: 1;
    font-size: 24pt !important;
    font-family: "Varela Round", "Almarai", sans-serif;
    text-shadow: 0 1px 0 blue, 0 2px 0 red, 0 3px 0 blue, 0 4px 0 red,
      0 20px 30px rgba(0, 0, 0, 0);
  }
  .body_text_EN {
    font-family: "Roboto Slab", serif;
    font-size: 14pt;
    color: #05182a;
  }

  .smaller {
    font-size: 10pt;
  }

  .section_EN .home_links {
    font-size: 14pt;
    font-family: "Ruda", sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .section_EN .home_links a {
    text-decoration: none;
    background-color: #d6304e;
    text-align: center;
    color: #fffacd;
    padding: 3%;
    margin-right: 5px;
    border: #372a6a solid;
    border-radius: 50px;
  }

  .section_EN .home_links a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (min-width: 541px) {
  .section_HE {
    padding-top: 50px;
    padding-right: 320px;
    background-color: inherit;
  }

  .rotating_message {
    font-size: 42pt;
    font-family: "Varela Round", "Almarai", sans-serif;
    text-shadow: 0 1px 0 blue, 0 2px 0 red, 0 3px 0 blue, 0 4px 0 red,
      0 20px 30px rgba(0, 0, 0, 0);
  }

  .body_text_HE {
    font-family: "Assistant", sans-serif;
    font-size: 16pt;
    margin-left: 40px;
    color: #05182a;
  }

  .smaller {
    font-size: 12pt;
  }

  .section_HE .home_links {
    font-size: 14pt;
    font-family: "Varela Round", sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .section_HE .home_links a {
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 1%;
    margin: 10px;
    border: #372a6a solid;
    border-radius: 50px;
  }

  .section_HE .home_links a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (max-width: 540px) {
  .section_HE {
    margin: 170px 10px 0 10px;
  }

  .rotating_message {
    font-size: 32pt;
    font-family: "Varela Round", "Almarai", sans-serif;
    text-shadow: 0 1px 0 blue, 0 2px 0 red, 0 3px 0 blue, 0 4px 0 red,
      0 20px 30px rgba(0, 0, 0, 0);
  }
  .body_text_HE {
    font-family: "Assistant", sans-serif;
    font-size: 16pt;
    color: #05182a;
  }

  .smaller {
    font-size: 12pt;
  }
  .section_HE .home_links {
    font-size: 14pt;
    font-family: "Varela Round", sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .section_HE .home_links a {
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 3%;
    margin-right: 5px;
    border: #372a6a solid;
    border-radius: 50px;
    text-align: center;
  }

  .section_HE .home_links a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (min-width: 541px) {
  .projects_list_EN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    margin-right: 90px;
    margin-left: 40px;
    list-style: none;
    padding: 0;
  }

  .projects_list_EN li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_EN li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_EN {
    font-family: "Ruda", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_EN {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_EN {
    list-style: none;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-family: "Roboto Slab", serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_EN li {
    margin-right: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_EN {
    font-family: "Roboto Slab", serif;
  }

  .project_links_EN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .project_links_EN a {
    margin-right: 10px;
    margin-bottom: 20px;
    font-family: "Ruda", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_EN a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (max-width: 540px) {
  .projects_list_EN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    list-style: none;
    margin-top: 15px;
    padding: 0;
  }

  .projects_list_EN li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_EN li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_EN {
    font-family: "Ruda", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_EN {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_EN {
    list-style: none;
    padding: 0 10px 0 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-family: "Roboto Slab", serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_EN li {
    margin-right: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_EN {
    font-family: "Roboto Slab", serif;
  }

  .project_links_EN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .project_links_EN a {
    margin-right: 10px;
    margin-bottom: 20px;
    font-family: "Ruda", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_EN a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (min-width: 541px) {
  .projects_list_HE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    margin-left: 90px;
    margin-right: 40px;
    list-style: none;
    padding: 0;
  }

  .projects_list_HE li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_HE li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_HE {
    font-family: "Varela Round", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_HE {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_HE {
    list-style: none;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-family: "Assistant", sans-serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_HE li {
    margin-left: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_HE {
    font-family: "Assistant", sans-serif;
  }

  .project_links_HE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .project_links_HE a {
    margin-left: 10px;
    margin-bottom: 20px;
    font-family: "Varela Round", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_HE a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (max-width: 540px) {
  .projects_list_HE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    list-style: none;
    margin-top: 15px;
    padding: 0;
  }

  .projects_list_HE li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_HE li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_HE {
    font-family: "Varela Round", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_HE {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_HE {
    list-style: none;
    padding: 0 10px 0 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-family: "Assistant", sans-serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_HE li {
    margin-left: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_HE {
    font-family: "Assistant", sans-serif;
  }

  .project_links_HE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .project_links_HE a {
    margin-left: 10px;
    margin-bottom: 20px;
    font-family: "Varela Round", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_HE a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (min-width: 541px) {
  .about_content_EN {
    font-family: "Roboto Slab", serif;
    font-size: 14pt;
    margin-right: 40px;
    color: #05182a;
    text-align: justify;
  }

  .about_image_EN img {
    margin-right: 30px;
    float: left;
    width: 15%;
  }
}

@media only screen and (max-width: 540px) {
  .about_content_EN {
    font-family: "Roboto Slab", serif;
    font-size: 14pt;
    color: #05182a;
    text-align: justify;
  }

  .about_image_EN img {
    margin-right: 30px;
    float: left;
    width: 30%;
  }
}

@media only screen and (min-width: 541px) {
  .about_content_HE {
    font-family: "Assistant", sans-serif;
    font-size: 14pt;
    margin-left: 40px;
    color: #05182a;
    text-align: justify;
  }

  .about_image_HE img {
    margin-left: 30px;
    float: right;
    width: 15%;
  }
}

@media only screen and (max-width: 540px) {
  .about_content_HE {
    font-family: "Assistant", sans-serif;
    font-size: 14pt;
    color: #05182a;
    text-align: justify;
  }

  .about_image_HE img {
    margin-left: 30px;
    float: right;
    width: 30%;
  }
}

.contact_logos_EN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0;
}

.contact_logos_EN img {
  width: 50px;
  margin-right: 5px;
}

#contact_EN .body_text_EN {
  text-align: justify;
}

.contact_logos_HE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0;
}

.contact_logos_HE img {
  width: 50px;
  margin-left: 5px;
}

#contact_HE .body_text_HE {
  text-align: justify;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #faf8fb;
  font-family: "Roboto Slab", serif;
  color: gray;
}

.footer_HE {
  font-family: "Assistant", sans-serif;
}

.secret {
  text-decoration: none;
  color: inherit;
  color: slategray;
}
.error_img {
    width: 250px;
}
