@media only screen and (min-width: 541px) {
  .about_content_HE {
    font-family: "Assistant", sans-serif;
    font-size: 14pt;
    margin-left: 40px;
    color: #05182a;
    text-align: justify;
  }

  .about_image_HE img {
    margin-left: 30px;
    float: right;
    width: 15%;
  }
}

@media only screen and (max-width: 540px) {
  .about_content_HE {
    font-family: "Assistant", sans-serif;
    font-size: 14pt;
    color: #05182a;
    text-align: justify;
  }

  .about_image_HE img {
    margin-left: 30px;
    float: right;
    width: 30%;
  }
}
