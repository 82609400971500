@media only screen and (min-width: 541px) {
  .projects_list_HE {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 90px;
    margin-right: 40px;
    list-style: none;
    padding: 0;
  }

  .projects_list_HE li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_HE li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_HE {
    font-family: "Varela Round", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_HE {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_HE {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    font-family: "Assistant", sans-serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_HE li {
    margin-left: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_HE {
    font-family: "Assistant", sans-serif;
  }

  .project_links_HE {
    display: flex;
    flex-direction: row;
  }

  .project_links_HE a {
    margin-left: 10px;
    margin-bottom: 20px;
    font-family: "Varela Round", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_HE a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}

@media only screen and (max-width: 540px) {
  .projects_list_HE {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 15px;
    padding: 0;
  }

  .projects_list_HE li {
    margin-bottom: 15px;
    border-radius: 15px;
    border: #372a6a solid 1px;
    background-color: #f7e9f9;
    color: #1d0029;
  }

  .projects_list_HE li img {
    border-radius: 15px;
    width: 100%;
  }

  .project_title_HE {
    font-family: "Varela Round", sans-serif;
    font-size: 21pt;
  }

  .project_list_content_HE {
    padding-left: 3%;
    padding-right: 3%;
  }

  .tech_list_HE {
    list-style: none;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    font-family: "Assistant", sans-serif;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tech_list_HE li {
    margin-left: 10px;
    background-color: #372a6a;
    color: #ffecdb;
    border-radius: 10px;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .project_desc_HE {
    font-family: "Assistant", sans-serif;
  }

  .project_links_HE {
    display: flex;
    flex-direction: row;
  }

  .project_links_HE a {
    margin-left: 10px;
    margin-bottom: 20px;
    font-family: "Varela Round", sans-serif;
    font-size: 16pt;
    text-decoration: none;
    background-color: #d6304e;
    color: #fffacd;
    padding: 0.5% 1%;
    border: #372a6a solid;
  }

  .project_links_HE a:hover {
    background-color: #372a6a;
    color: #ffecdb;
  }
}
