@import url('https://fonts.googleapis.com/css2?family=Assistant&family=Roboto+Slab&family=Ruda&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai&family=Long+Cang&display=swap');

html {
    background-color: #faf8fb; ; 
}

body {
    min-height: 400px;
    margin-bottom: 100px;
    clear: both;
}

.nav_EN, .nav_HE, section {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}